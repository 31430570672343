var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "homeHeader x3 aic"
  }, [_c('img', {
    staticClass: "logo-img",
    attrs: {
      "src": require("../../assets/opening_logo.png")
    }
  }), _c('div', {
    staticClass: "menus x aic"
  }, _vm._l(_vm.menus, function (item, index) {
    return _c('p', {
      key: index,
      staticClass: "fs18 bold pointer",
      class: {
        active: index === _vm.activeIndex
      },
      on: {
        "click": function click($event) {
          return _vm.chooseItem(index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c('div', {
    staticClass: "x aic"
  }, [_c('div', {
    staticClass: "x aic pfff mr24"
  }, [_c('img', {
    staticClass: "avatar",
    attrs: {
      "src": _vm.estateInfo.estateLogo || _vm.$defaultAvatarUrl
    }
  }), _c('p', {
    staticClass: "pw8 estateName ellipsis"
  }, [_vm._v(" " + _vm._s(_vm.estateInfo.estateName) + " ")])]), _c('logout', {
    staticClass: "pfff"
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }