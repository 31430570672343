<template>
  <div class="home y">
    <openingHeader />
    <el-main class="colorBg flex1">
      <router-view />
    </el-main>
  </div>
</template>
<script>
import openingHeader from "./openingHeader";
export default {
  components: {
    openingHeader
  }
};
</script>

<style lang="scss" scoped>
.home {
  min-width: 1080px;
  overflow-x: auto;
  min-height: 100vh;
  ::v-deep {
    .account-bar {
      .name {
        color: #fff;
      }
    }
  }
}
</style>
