<template>
  <header class="homeHeader x3 aic">
    <img src="../../assets/opening_logo.png" class="logo-img" />
    <div class="menus x aic">
      <p
        class="fs18 bold pointer"
        :class="{ active: index === activeIndex }"
        v-for="(item, index) in menus"
        :key="index"
        @click="chooseItem(index)"
      >
        {{ item.name }}
      </p>
    </div>
    <div class="x aic">
      <div class="x aic pfff mr24">
        <img :src="estateInfo.estateLogo || $defaultAvatarUrl" class="avatar" />
        <p class="pw8 estateName ellipsis">
          {{ estateInfo.estateName }}
        </p>
      </div>
      <logout class="pfff" />
    </div>
  </header>
</template>

<script>
import logout from "@/components/logout";
export default {
  components: {
    logout
  },
  data() {
    return {
      menus: [
        {
          id: "index",
          name: "开盘活动"
        },
        {
          id: "house",
          name: "房源管理"
        },
        {
          id: "car",
          name: "车位管理"
        },
        {
          id: "generalLayout",
          name: "总平图"
        },
        {
          id: "subscriptionFormula",
          name: "认购公示"
        },
        {
          id: "marketing",
          name: "营销活动"
        }
      ],
      activeIndex: 0
    };
  },
  computed: {
    estateInfo() {
      //当前项目信息
      return this.$store.state.estateInfo;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let path = this.$route.path;
      let arr = path.split("/");
      let id = arr[arr.length - 1];
      let activeIndex = 0;
      this.menus.forEach((e, i) => {
        if (e.id == id) {
          activeIndex = i;
        }
      });
      this.activeIndex = activeIndex;
    },
    chooseItem(index) {
      this.activeIndex = index;
      let path = this.menus[index].id;
      this.$router.push({
        path: `/opening/${path}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.homeHeader {
  background-color: #182042;
  height: 70px;
  border-bottom: 1px solid $bdColor;
  padding: 0 20px;
  .logo-img {
    height: 35px;
    margin-top: -13px;
  }
  .menus {
    & > p {
      margin-right: 40px;
      color: rgba($color: #fff, $alpha: 0.7);
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: #fff;
        font-size: 20px;
      }
    }
  }
  .pfff {
    color: #fff;
    p {
      color: #fff;
    }
  }
  .estateName {
    max-width: 150px;
  }
}
</style>
